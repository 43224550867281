// components/Icons.js
import React from 'react';

export const InventariumIsotype = () => (
    <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2 go-brand'>
        <path className="path-stroke-primary" d="M14.1667 1.66667C16.6667 1.66667 18.3334 3.33334 18.3334 5.83334V7.50001" stroke="var(--text-color)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-secondary" d="M1.66675 7.50001V5.83334C1.66675 3.33334 3.33341 1.66667 5.83341 1.66667H10.6418" stroke="var(--theme-color)" stroke-opacity="0.988235" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-secondary" d="M18.3333 12.5V14.1667C18.3333 16.6667 16.6667 18.3333 14.1667 18.3333H9.55835" stroke="var(--theme-color)" stroke-opacity="0.988235" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M1.66675 12.5V14.1667C1.66675 16.6667 3.33341 18.3333 5.83341 18.3333" stroke="var(--text-color)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M5.58325 7.71667L9.99992 10.275L14.3833 7.73336" stroke="var(--text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M10 14.8084V10.2667" stroke="var(--text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M8.96661 5.24166L6.29994 6.72502C5.69994 7.05835 5.19995 7.90001 5.19995 8.59168V11.4167C5.19995 12.1084 5.69161 12.95 6.29994 13.2833L8.96661 14.7667C9.53328 15.0833 10.4666 15.0833 11.0416 14.7667L13.7083 13.2833C14.3083 12.95 14.8083 12.1084 14.8083 11.4167V8.59168C14.8083 7.90001 14.3166 7.05835 13.7083 6.72502L11.0416 5.24166C10.4666 4.91666 9.53328 4.91666 8.96661 5.24166Z" stroke="var(--text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export const InventariumIsotypeWhite = () => (
    <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2 go-brand'>
        <path className="path-stroke-primary" d="M14.1667 1.66667C16.6667 1.66667 18.3334 3.33334 18.3334 5.83334V7.50001" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-secondary" d="M1.66675 7.50001V5.83334C1.66675 3.33334 3.33341 1.66667 5.83341 1.66667H10.6418" stroke="var(--theme-color)" stroke-opacity="0.988235" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-secondary" d="M18.3333 12.5V14.1667C18.3333 16.6667 16.6667 18.3333 14.1667 18.3333H9.55835" stroke="var(--theme-color)" stroke-opacity="0.988235" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M1.66675 12.5V14.1667C1.66675 16.6667 3.33341 18.3333 5.83341 18.3333" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M5.58325 7.71667L9.99992 10.275L14.3833 7.73336" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M10 14.8084V10.2667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path className="path-stroke-primary" d="M8.96661 5.24166L6.29994 6.72502C5.69994 7.05835 5.19995 7.90001 5.19995 8.59168V11.4167C5.19995 12.1084 5.69161 12.95 6.29994 13.2833L8.96661 14.7667C9.53328 15.0833 10.4666 15.0833 11.0416 14.7667L13.7083 13.2833C14.3083 12.95 14.8083 12.1084 14.8083 11.4167V8.59168C14.8083 7.90001 14.3166 7.05835 13.7083 6.72502L11.0416 5.24166C10.4666 4.91666 9.53328 4.91666 8.96661 5.24166Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export const AnotherIcon = () => (
  <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Another SVG paths here */}
  </svg>
);

// Add more icons as needed
