import React, { useState, useRef, useContext, useEffect } from 'react';
import { Nav, Modal, Button, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CashRegisterContext } from '../../../contexts/GoMarket/CashRegisterContext';
import { enabled_services as serviceMapping } from '../../../utils/Constants'; // Import the service mapping
import { InventariumIsotype } from '../Icons/Icons';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activePath, setActivePath] = useState(currentPath);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const imgRefs = useRef({});
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  const { currentPendingOrders, currentDailyBookings, enabledServices } = useContext(CashRegisterContext);

  // Handle resize for desktop/mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.matchMedia("(min-width: 768px)").matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Listen for beforeinstallprompt event
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setShowInstallButton(true); // Show the install button when the app is ready for installation
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
    };
  }, []);

  const isActive = (path, exact = false) => {
    return exact ? activePath === path : activePath.startsWith(`${path}/`) || activePath === path;
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/admin_login');
  };

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleNavLinkClick = (path) => (event) => {
    if (path === '/logout') {
      handleLogoutClick(event);
    } else {
      setActivePath(path);
    }
  };

  const handleFillClick = () => {
    if (!isDesktop && isOpen) {
      setIsOpen(false)
    }
  };

  // Handle PWA installation click
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false); // Hide the button after installation
      });
    }
  };

  // Base navigation items
 // Base navigation items
const navItemBase = [
  { path: '/admin_dashboard', label: 'Dashboard', icon: 'home' },
  { path: '/inteligencia-artifical', label: 'IA', icon: 'ai', extraClass: 'go-disabled' },

  // Conditionally add the Install button if the PWA can be installed
  showInstallButton
    ? { label: 'Instalar', icon: 'receive-square', extraClass: 'mt-auto', onClick: handleInstallClick }
    : null, // Render nothing if the install button is not needed

  { path: '/logout', label: 'Salir', icon: 'logout', extraClass: showInstallButton ? '' : 'mt-auto' }, // Logout button
];



  // Service-specific navigation items
  const navItemsGoMarket = [
    { path: '/admin/punto-de-venta', label: 'Punto de venta', icon: 'shop', exact: true, badge: currentDailyBookings || 0 },
    { path: '/admin/inventario', label: 'Inventario', icon: 'box' },
    { path: '/admin/historial-cajas', label: 'Cajas', icon: 'strongbox' },
    { path: '/admin/ventas', label: 'Ventas', icon: 'ventas' },
    { path: '/admin/pendientes', label: 'Pendientes', icon: 'pendientes', badge: currentPendingOrders || 0 },
    { path: '/admin/reporteria', label: 'Reporteria', icon: 'stadistics' },
  ];

  const navItemsGoCalendar = [
    { path: '/admin/reservas', label: 'Reservas', icon: 'calendar' },
  ];

  const navItemsGoBalance = [
    { path: '/admin/balances', label: 'Balances', icon: 'registers' },
  ];

// Combine base and service-specific navigation items and filter out null values
const navItems = [...navItemBase].filter(Boolean); // Remove null values

  enabledServices.forEach(serviceId => {
    const service = serviceMapping.find(s => s.id === serviceId);

    if (service) {
      if (service.name === 'GoMatch') {
        navItems.splice(1, 0, ...navItemsGoCalendar); // Insert after Dashboard (at index 1)
      } else if (service.name === 'GoMarket') {
        navItems.splice(1, 0, ...navItemsGoMarket); // Insert after Dashboard (at index 1)
      } else if (service.name === 'GoRegisters') {
        navItems.splice(1, 0, ...navItemsGoBalance); // Insert after Dashboard (at index 1)
      }
    }
  });

  const renderNavLink = ({ path, label, icon, exact, extraClass, badge, onClick }) => {
    const isLinkActive = isActive(path, exact);
  
    return (
      <Nav.Link
        key={path || label}  // Ensure unique keys
        as={Link}
        to={path === '/logout' || onClick ? '#' : path}  // Prevent navigation for actions like logout or install
        className={`${extraClass ? extraClass : ''} go-sidebar__item nav-link ${isLinkActive ? 'active' : ''}`}
        onClick={onClick ? onClick : handleNavLinkClick(path)} // Use provided onClick or default nav handler
      >
        <img
          ref={(el) => (imgRefs.current[path] = el)}
          data-icon={icon}
          src={`/assets/icons/menu/${icon}.svg`}
          alt={label}
        />
        {label}
        {badge !== undefined && badge > 0 && (
          <div bg="danger" className={`ms-2 notification-badge ${badge ? '' : 'd-none'}`} />
        )}
      </Nav.Link>
    );
  };
  

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''} go-sidebar`}>
        <Nav className="flex-column h-100 pb-3">
          <Link to='/admin_dashboard' className='go-header__brand ps-4 pt-2 pb-4'>
            <div className='go-sidebar__brand'> 
              <InventariumIsotype />
              <span>In</span>
              <span className='blue'>venta</span><span>rium</span> 
            </div>
          </Link>
          {navItems.slice(0, 3).map(renderNavLink)}
          {navItems.slice(3, 8).map(renderNavLink)}
          {navItems.slice(8).map(renderNavLink)}

        

        
        </Nav>
      </div>

      {!isDesktop && isOpen && (
        <div className='go-sidebar-fill' onClick={handleFillClick} />
      )}

      <Modal show={showLogoutModal} className='go-modal go-modal--payment' centered onHide={handleCloseLogoutModal}>
        <Modal.Body>
          <h4 className='title'>Estás seguro que quieres salir?</h4>
          <p className='go-text'>Te esperaremos aquí mismo :)</p>
          <Row className='pt-4'>
            <Col className="col-md-6 col-12">
              <Button variant="secondary" className='go-btn go-btn--close' onClick={handleCloseLogoutModal}>
                Volver
              </Button>
            </Col>
            <Col className="col-md-6 col-12">
              <Button variant="primary" className='go-btn go-btn--add' onClick={handleLogout}>
                Cerrar sesión
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
